import React, { useState, useEffect } from 'react'
import { graphql } from 'gatsby'

import { PageTemplate } from 'src/templates/page'
import { getSearchQuery } from '@walltowall/helpers'

const SEARCH_PARAM = 'query'

const SearchPage = ({ location, ...props }) => {
  const [query, setQuery] = useState(getSearchQuery(SEARCH_PARAM, location))

  useEffect(() => {
    setQuery(getSearchQuery(SEARCH_PARAM, location))
  }, [location])

  const injectSlices = (list = []) => [
    {
      __typename: 'PageBodyHeader',
      id: 'header',
      initialQuery: query,
      isSearchInitiallyOpen: true,
    },
    ...list,
    { __typename: 'PageBodySearchResults', id: 'search-results', query },
    { __typename: 'PageBodyFooter', id: 'footer' },
  ]

  return (
    <PageTemplate
      listMiddleware={injectSlices}
      location={location}
      {...props}
    />
  )
}

export default SearchPage

export const query = graphql`
  query($uid: String!) {
    prismicPage(uid: { eq: $uid }) {
      ...PageParentRecursive
      uid
      data {
        title {
          text
        }
        meta_title
        meta_description
        body {
          __typename
        }
      }
    }
    ...SlicesPageBody
  }
`
